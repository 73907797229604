@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap');

#root{
  font-family: 'Open Sans', sans-serif;
}


.back-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background: #4281a4;
  color: white;
  border: none;
  box-shadow: 0 5px 10px #ccc;
}