@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.header-content{
  background-color: #4281a4;
  color: white;
}

.lang{
  cursor: pointer;
}

.link{
  color: whitesmoke;
}
.resume{
  text-align: center;
}
.about-content{
  background-color: #48a9a6;
  color: white;
}

.propic{
  width: 150px;
  height: 150px;
  border-radius: 50%;
}
.skills-content{
  background-color: #e4dfda;
  color: black;
}


#root{
  font-family: 'Open Sans', sans-serif;
}


.back-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background: #4281a4;
  color: white;
  border: none;
  box-shadow: 0 5px 10px #ccc;
}
