.header-content{
  background-color: #4281a4;
  color: white;
}

.lang{
  cursor: pointer;
}

.link{
  color: whitesmoke;
}